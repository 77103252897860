import {useState, useEffect} from 'react';
import Header from './../components/header.js';
import IntPeso from '../components/int_peso.js';
import IntDist from '../components/int_dist.js';
import IntVol from '../components/int_vol.js';

export default function Internacionais (){
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  return(
    <>
      <Header />
      <div className="medidas_facil">
        <IntPeso isMobile={false} title="Medidas internacionais de peso"/>
        <IntDist isMobile={isMobile} title="Medidas internacionais de distancia"/>
        <IntVol isMobile={isMobile} title="Medidas internacionais de volume"/>
        <div id="medidas_informacoes">
          <h2>As medidas fora do padrão</h2>
          <p>
            <span>Diversas</span> medidas, que não são padronizadas, são usadas até hoje.<br/>
            Por exemplo: Para medir uma televisão usamos as polegadas. <br/>
            <span>Se</span> você ver um anúncio de uma televisão de 1 metro e 40 centimetros,
            certamente você vai achar estranho até descobrir que estamos falando de 
            uma televisão de 55 polegadas.
          </p>
          <p>
            <span>No</span> ramo da aviação também é comum usar uma medida fora do padrão. Se
            você já esteve em um voo, com certeza já escutou o piloto dizer: <br/>
            <span>-</span> Estamos agora em uma altitude de 7.000 pés.
          </p>
        </div>
      </div>
    </>
  )
}
import React from 'react';

export default class IntVol extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      title: this.props.title,
      arr: ["mililitro (ml)", "litro (l)", "onça liquida ou fluid once (fl. oz)", "copo ou cup", "pint ou pinta", "quarto liquido ou quart", "galão ou gallon (gal)"],
      origem: {
        posicao: 0,
        valor: 1,
      },
      destino: {
        posicao: 0,
        valor: 1,
      },
      component_is_active: this.props.isMobile,
      toogle_class_check: this.props.isMobile? 'hide' : ''
    };
  }

  inputOrigemRecalculaValores(evento){
    let pos_origem = this.state.origem.posicao;
    let valor_origem = evento.target.value;
    let pos_destino = this.state.destino.posicao;
    let valor_destino = evento.target.value;

    this.calculaValor(pos_origem, valor_origem, pos_destino, valor_destino);
  }

  selectOrigemRecalculaValores(evento){
    let pos_origem = parseInt(evento.target.value);
    let valor_origem = this.state.origem.valor;
    let pos_destino = this.state.destino.posicao;
    let valor_destino = this.state.origem.valor;
    
    this.calculaValor(pos_origem, valor_origem, pos_destino, valor_destino);
  }

  selectDestinoRecalculaValores(evento){
    let pos_origem = this.state.origem.posicao;
    let valor_origem = this.state.origem.valor;
    let pos_destino = parseInt(evento.target.value);
    let valor_destino = this.state.origem.valor;

    this.calculaValor(pos_origem, valor_origem, pos_destino, valor_destino);
  }

  calculaValor(pos_origem, valor_origem, pos_destino, valor_destino){
    if(pos_origem === 0){      
      if(pos_destino === 1){
        valor_destino = parseFloat(valor_destino/1000).toFixed(2);
      }else if(pos_destino === 2){
        valor_destino = parseFloat(valor_destino/29.574).toFixed(2);
      }else if(pos_destino === 3){
        valor_destino = parseFloat(valor_destino/240).toFixed(2);
      }else if(pos_destino === 4){
        valor_destino = parseFloat(valor_destino/473.2).toFixed(2);
      }else if(pos_destino === 5){
        valor_destino = parseFloat(valor_destino/946.4).toFixed(2);
      }else if(pos_destino === 6){
        valor_destino = parseFloat(valor_destino/3785).toFixed(2);
      }
    } else if(pos_origem === 1){
      if(pos_destino === 0){
        valor_destino = parseFloat(valor_destino*1000).toFixed(2);
      }else if(pos_destino === 2){
        valor_destino = parseFloat(valor_destino*33.814).toFixed(2);
      }else if(pos_destino === 3){
        valor_destino = parseFloat(valor_destino*4.167).toFixed(2);
      }else if(pos_destino === 4){
        valor_destino = parseFloat(valor_destino*2.113).toFixed(2);
      }else if(pos_destino === 5){
        valor_destino = parseFloat(valor_destino*1.057).toFixed(2);
      }else if(pos_destino === 6){
        valor_destino = parseFloat(valor_destino/3.785).toFixed(2);
      }
    } else if(pos_origem === 2){
      if(pos_destino === 0){
        valor_destino = parseFloat(valor_destino*29.754).toFixed(2);
      }else if(pos_destino === 1){
        valor_destino = parseFloat(valor_destino/33.814).toFixed(2);
      }else if(pos_destino === 3){
        valor_destino = parseFloat(valor_destino/8.115).toFixed(2);
      }else if(pos_destino === 4){
        valor_destino = parseFloat(valor_destino/16).toFixed(2);
      }else if(pos_destino === 5){
        valor_destino = parseFloat(valor_destino/32).toFixed(2);
      }else if(pos_destino === 6){
        valor_destino = parseFloat(valor_destino/128).toFixed(2);
      }
    } else if(pos_origem === 3){
      if(pos_destino === 0){
        valor_destino = parseFloat(valor_destino*240).toFixed(2);
      }else if(pos_destino === 1){
        valor_destino = parseFloat(valor_destino/4.167).toFixed(2);
      }else if(pos_destino === 2){
        valor_destino = parseFloat(valor_destino*8.115).toFixed(2);
      }else if(pos_destino === 4){
        valor_destino = parseFloat(valor_destino/1.972).toFixed(2);
      }else if(pos_destino === 5){
        valor_destino = parseFloat(valor_destino/3.943).toFixed(2);
      }else if(pos_destino === 6){
        valor_destino = parseFloat(valor_destino/15.772).toFixed(2);
      }
    } else if(pos_origem === 4){
      if(pos_destino === 0){
        valor_destino = parseFloat(valor_destino*473.2).toFixed(2);
      }else if(pos_destino === 1){
        valor_destino = parseFloat(valor_destino/2.113).toFixed(2);
      }else if(pos_destino === 2){
        valor_destino = parseFloat(valor_destino*16).toFixed(2);
      }else if(pos_destino === 3){
        valor_destino = parseFloat(valor_destino*1.972).toFixed(2);
      }else if(pos_destino === 5){
        valor_destino = parseFloat(valor_destino/2).toFixed(2);
      }else if(pos_destino === 6){
        valor_destino = parseFloat(valor_destino/8).toFixed(2);
      }
    } else if(pos_origem === 5){
      if(pos_destino === 0){
        valor_destino = parseFloat(valor_destino*946.4).toFixed(2);
      }else if(pos_destino === 1){
        valor_destino = parseFloat(valor_destino/1.057).toFixed(2);
      }else if(pos_destino === 2){
        valor_destino = parseFloat(valor_destino*32).toFixed(2);
      }else if(pos_destino === 3){
        valor_destino = parseFloat(valor_destino*3.943).toFixed(2);
      }else if(pos_destino === 4){
        valor_destino = parseFloat(valor_destino*2).toFixed(2);
      }else if(pos_destino === 6){
        valor_destino = parseFloat(valor_destino/4).toFixed(2);
      }
    } else if(pos_origem === 6){
      if(pos_destino === 0){
        valor_destino = parseFloat(valor_destino*3785).toFixed(2);
      }else if(pos_destino === 1){
        valor_destino = parseFloat(valor_destino*3.785).toFixed(2);
      }else if(pos_destino === 2){
        valor_destino = parseFloat(valor_destino*128).toFixed(2);
      }else if(pos_destino === 3){
        valor_destino = parseFloat(valor_destino*15.773).toFixed(2);
      }else if(pos_destino === 4){
        valor_destino = parseFloat(valor_destino*8).toFixed(2);
      }else if(pos_destino === 5){
        valor_destino = parseFloat(valor_destino*4).toFixed(2);
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  toogleElement(e){
    let component_is_active = !this.state.component_is_active;

    this.setState({component_is_active: component_is_active, toogle_class_check: component_is_active ? 'hide': ''})
  }

  render(){
    let options = this.state.arr.map((medida, k) => {
      return(
        <option value={k} key={k}>{medida}</option>
      );
    });

    return(
      <div className="medida_conteudo">
        <div className="titulos" onClick={() => this.toogleElement()}> 
          <span>{this.state.title}</span>
          <span className={`olho_conteudo ${this.state.toogle_class_check}`}></span> 
        </div>
        <div className={`medida_box ${this.state.toogle_class_check}`}>
          <span className="medida_conteudo_origem">
            <div className="div_opcoes_medidas">
              <select className="opcoes_medidas" onChange={(event) => {this.selectOrigemRecalculaValores(event)}}>
                {options}
              </select>
            </div><br/>
            <input type="text" className="input_text_medida input_origem" value={this.state.origem.valor} onChange={(event) => {this.inputOrigemRecalculaValores(event)}}></input>
          </span>
          <span className="equals"> = </span>
          <span className="medida_conteudo_destino">
            <div className="div_opcoes_medidas">
              <select className="opcoes_medidas" onChange={(event) => {this.selectDestinoRecalculaValores(event)}}>
                {options}
              </select>
            </div><br/>
            <input type="text" className="input_text_medida input_destino" value={this.state.destino.valor} disabled></input>
          </span>
        </div>
      </div>
    )
  }
}
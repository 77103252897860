import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/home.js';
import Internacionais from './pages/internacionais.js';
import Temperatura from './pages/temperatura.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/internacionais" element={<Internacionais />} />
        <Route path="/conversor_de_temperaturas" element={<Temperatura />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;

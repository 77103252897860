import React from 'react';

export default class Tempo extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      title: this.props.title,
      arr: this.props.array,
      origem: {
        posicao: 0,
        valor: 1,
      },
      destino: {
        posicao: 0,
        valor: 1,
      },
      component_is_active: this.props.isMobile,
      toogle_class_check: this.props.isMobile? 'hide' : ''
    };
  }

  inputOrigemRecalculaValores(evento){
    let pos_origem = this.state.origem.posicao;
    let valor_origem = evento.target.value;
    let pos_destino = this.state.destino.posicao;
    let valor_destino = evento.target.value;

    if(pos_origem < pos_destino){
      for(let i=pos_origem;i<pos_destino;i++){
        if(i < 3){
          valor_destino = valor_destino/10;
        }else if(i === 3 || i === 4){
          valor_destino = valor_destino/60;
        }else if(i === 5){
          valor_destino = valor_destino/24;
        }else if(i === 6){
          valor_destino = valor_destino/7;
        }
      }
    }else if(pos_origem > pos_destino){
      for(let i=pos_origem;i>pos_destino;i--){
        if(i === 7){
          valor_destino = valor_destino*7;
        }else if(i === 6){
          valor_destino = valor_destino*24;
        }else if(i === 5 || i === 4){
          valor_destino = valor_destino*60;
        }else if(i < 4){
          valor_destino = valor_destino*10;
        }
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  selectOrigemRecalculaValores(evento){
    let pos_origem = parseInt(evento.target.value);
    let valor_origem = this.state.destino.valor;
    let pos_destino = this.state.destino.posicao;
    let valor_destino = this.state.destino.valor;
    
    if(pos_origem < pos_destino){
      for(let i=pos_origem;i<pos_destino;i++){
        if(i < 3){
          valor_origem = valor_origem*10;
        }else if(i === 3 || i === 4){
          valor_origem = valor_origem*60;
        }else if(i === 5){
          valor_origem = valor_origem*24;
        }else if(i === 6){
          valor_origem = valor_origem*7;
        }
      }
    }else if(pos_origem > pos_destino){
      for(let i=pos_origem;i>pos_destino;i--){
        if(i === 7){
          valor_origem = valor_origem/7;
        }else if(i === 6){
          valor_origem = valor_origem/24;
        }else if(i === 5 || i === 4){
          valor_origem = valor_origem/60;
        }else if(i < 4){
          valor_origem = valor_origem/10;
        }
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  inputDestinoRecalculaValores(evento){
    let pos_origem = this.state.origem.posicao;
    let valor_origem = evento.target.value;
    let pos_destino = this.state.destino.posicao;
    let valor_destino = evento.target.value;

    if(pos_origem < pos_destino){
      for(let i=pos_origem;i<pos_destino;i++){
        if(i < 3){
          valor_origem = valor_origem*10;
        }else if(i === 3 || i === 4){
          valor_origem = valor_origem*60;
        }else if(i === 5){
          valor_origem = valor_origem*24;
        }else if(i === 6){
          valor_origem = valor_origem*7;
        }
      }
    }else if(pos_origem > pos_destino){
      for(let i=pos_origem;i>pos_destino;i--){
        if(i === 7){
          valor_origem = valor_origem/7;
        }else if(i === 6){
          valor_origem = valor_origem/24;
        }else if(i === 5 || i === 4){
          valor_origem = valor_origem/60;
        }else if(i < 4){
          valor_origem = valor_origem/10;
        }
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  selectDestinoRecalculaValores(evento){
    let pos_origem = this.state.origem.posicao;
    let valor_origem = this.state.origem.valor;
    let pos_destino = parseInt(evento.target.value);
    let valor_destino = this.state.origem.valor;

    if(pos_origem < pos_destino){
      for(let i=pos_origem;i<pos_destino;i++){
        if(i < 3){
          valor_destino = valor_destino/10;
        }else if(i === 3 || i === 4){
          valor_destino = valor_destino/60;
        }else if(i === 5){
          valor_destino = valor_destino/24;
        }else if(i === 6){
          valor_destino = valor_destino/7;
        }
      }
    }else if(pos_origem > pos_destino){
      for(let i=pos_origem;i>pos_destino;i--){
        if(i === 7){
          valor_destino = valor_destino*7;
        }else if(i === 6){
          valor_destino = valor_destino*24;
        }else if(i === 5 || i === 4){
          valor_destino = valor_destino*60;
        }else if(i < 4){
          valor_destino = valor_destino*10;
        }
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  toogleElement(e){
    let component_is_active = !this.state.component_is_active;

    this.setState({component_is_active: component_is_active, toogle_class_check: component_is_active ? 'hide': ''})
  }

  render(){
    let options = this.state.arr.map((medida, k) => {
      return(
        <option value={k} key={k}>{medida}</option>
      );
    });

    return(
      <div className="medida_conteudo">
        <div className="titulos" onClick={() => this.toogleElement()}> 
          <span>{this.state.title}</span>
          <span className={`olho_conteudo ${this.state.toogle_class_check}`}></span> 
        </div>
        <div className={`medida_box ${this.state.toogle_class_check}`}>
          <span className="medida_conteudo_origem">
            <div className="div_opcoes_medidas">
              <select className="opcoes_medidas" onChange={(event) => {this.selectOrigemRecalculaValores(event)}}>
                  {options}
              </select>
            </div><br/>
            <input type="text" className="input_text_medida input_origem" value={this.state.origem.valor} onChange={(event) => {this.inputOrigemRecalculaValores(event)}}></input>
          </span>
          <span className="equals"> = </span>
          <span className="medida_conteudo_destino">
            <div className="div_opcoes_medidas">
              <select className="opcoes_medidas" onChange={(event) => {this.selectDestinoRecalculaValores(event)}}>
                  {options}
              </select>
            </div><br/>
            <input type="text" className="input_text_medida input_destino" value={this.state.destino.valor} onChange={(event) => {this.inputDestinoRecalculaValores(event)}}></input>
          </span>
        </div>
      </div>
    )
  }
}
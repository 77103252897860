import Temperatura from './../components/temperatura.js';
import Header from './../components/header.js';

export default function Home() {
  return (
    <>
      <Header />
      <div className="medidas_facil">
        <Temperatura title="Conversor de temperaturas" />
        <div id="medidas_informacoes">
          <h2>As unidades de medidas de temperaturas</h2>
          <h3>Kelvin</h3>
          <p>
            <span>O</span> Kelvin (K) é unidade básica de medida do Sistema Internacional de Medidas
            para temperaturas.<br/>
            <span>Essa</span> unidade de medida foi proposta por William Thomson em 1848. William achou 
            necessário que existisse uma escala onde o <strong>zero absoluto </strong> 
            fosse o ponto nulo da escala. <br/>
            <span>Para</span> fazer a conversão de Kelvin para Celcius usamos o seguinte cálculo: <br/>
            <span className="informacoes_calculo">1 °C = 1 K - 273.15</span>
          </p>
          <h3>Fahrenheit</h3>
          <p>
            <span>A</span> escala de Fahrenheit (°F) é muito utilizada em países de língua inglesa, 
            como Estados Unidos e Inglaterra. <br/>
            <span>Foi</span> proposta por Daniel Gabriel Fahrenheit em 1724. Nela o ponto de
            fusão e ebulição da água correspondem a 32 °F e 212 °F. A diferença de 1 °C é igual
            a diferença de 1,8 °F. <br/>
            <span>Para</span> fazer a conversão de Fahrenheit para Celcius usamos o seguinte cálculo: <br/>
            <span className="informacoes_calculo">1 °C = (1 °F - 32)/1,8</span>
          </p>
          <h3>Celcius</h3>
          <p>
            <span>A</span> escala de graus Celcius (°C) ou escala centígrada é mais usada no mundo. <br/>
            <span>Ela</span> foi proposta por Anders Celcius, tendo como base a ponto de fusão e de 
            ebulição da água, onde 0 °C corresponde ao ponto de fusão e 100 °C corresponde
            ao ponto de ebulição da água.
          </p>
        </div>
      </div>
    </>
  );
}
import React from 'react';

export default class Medidas extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      title: this.props.title,
      arr: this.props.array,
      valor_conversao: this.props.valor_conversao,
      origem: {
        posicao: 0,
        valor: 1,
      },
      destino: {
        posicao: 0,
        valor: 1,
      },
      component_is_active: this.props.isMobile,
      toogle_class_check: this.props.isMobile? 'hide' : ''
    };
  }

  inputOrigemRecalculaValores(evento){
    let pos_origem = this.state.origem.posicao;
    let valor_origem = evento.target.value;
    let pos_destino = this.state.destino.posicao;
    let valor_destino = evento.target.value;

    let dif = Math.abs(pos_origem-pos_destino);

    if(pos_origem < pos_destino){
      for(let i=0;i<dif;i++){
        valor_destino = valor_destino/this.state.valor_conversao;
      }
    }else if(pos_origem > pos_destino){
      for(let i=0;i<dif;i++){
        valor_destino = valor_destino*this.state.valor_conversao;
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  selectOrigemRecalculaValores(evento){
    let pos_origem = parseInt(evento.target.value);
    let valor_origem = this.state.destino.valor;
    let pos_destino = this.state.destino.posicao;
    let valor_destino = this.state.destino.valor;
    
    let dif = Math.abs(pos_origem-pos_destino);

    if(pos_origem < pos_destino){
      for(let i=0;i<dif;i++){
        valor_origem = valor_origem*this.state.valor_conversao;
      }
    }else if(pos_origem > pos_destino){
      for(let i=0;i<dif;i++){
        valor_origem = valor_origem/this.state.valor_conversao;
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  inputDestinoRecalculaValores(evento){
    let pos_origem = this.state.origem.posicao;
    let valor_origem = evento.target.value;
    let pos_destino = this.state.destino.posicao;
    let valor_destino = evento.target.value;

    let dif = Math.abs(pos_origem-pos_destino);

    if(pos_origem < pos_destino){
      for(let i=0;i<dif;i++){
        valor_origem = valor_origem*this.state.valor_conversao;
      }
    }else if(pos_origem > pos_destino){
      for(let i=0;i<dif;i++){
        valor_origem = valor_origem/this.state.valor_conversao;
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  selectDestinoRecalculaValores(evento){
    let pos_origem = this.state.origem.posicao;
    let valor_origem = this.state.origem.valor;
    let pos_destino = parseInt(evento.target.value);
    let valor_destino = this.state.origem.valor;

    let dif = Math.abs(pos_origem-pos_destino);

    if(pos_origem < pos_destino){
      for(let i=0;i<dif;i++){
        valor_destino = valor_destino/this.state.valor_conversao;
      }
    }else if(pos_origem > pos_destino){
      for(let i=0;i<dif;i++){
        valor_destino = valor_destino*this.state.valor_conversao;
      }
    }

    this.setState({origem: {posicao: pos_origem, valor: valor_origem}, destino: {posicao: pos_destino, valor: valor_destino}});
  }

  toogleElement(e){
    let component_is_active = !this.state.component_is_active;

    this.setState({component_is_active: component_is_active, toogle_class_check: component_is_active ? 'hide': ''})
  }

  render(){
    let options = this.state.arr.map((medida, k) => {
      return(
        <option value={k} key={k}>{medida}</option>
      );
    });

    return(
      <div className="medida_conteudo">
        <div className="titulos" onClick={() => this.toogleElement()}> 
          <span>{this.state.title}</span>
          <span className={`olho_conteudo ${this.state.toogle_class_check}`}></span> 
        </div>
        <div className={`medida_box ${this.state.toogle_class_check}`}>
          <span className="medida_conteudo_origem">
            <div className="div_opcoes_medidas">
              <select className="opcoes_medidas" onChange={(event) => {this.selectOrigemRecalculaValores(event)}}>
                {options}
              </select>
            </div><br/>
            <input type="text" className="input_text_medida input_origem" value={this.state.origem.valor} onChange={(event) => {this.inputOrigemRecalculaValores(event)}}></input>
          </span>
          <span className="equals"> = </span>
          <span className="medida_conteudo_destino">
            <div className="div_opcoes_medidas">
              <select className="opcoes_medidas" onChange={(event) => {this.selectDestinoRecalculaValores(event)}}>
                {options}
              </select>
            </div><br/>
            <input type="text" className="input_text_medida input_destino" value={this.state.destino.valor} onChange={(event) => {this.inputDestinoRecalculaValores(event)}}></input>
          </span>
        </div>
      </div>
    )
  }
}
import {useState, useEffect} from 'react';
import Medidas from './../components/medidas.js';
import Tempo from './../components/tempo.js';
import Header from './../components/header.js';

export default function Home() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  return (
    <>
      <Header />
      <div className="medidas_facil">
        <Medidas isMobile={false} title="Medidas de comprimento" array={["milimetro (mm)", "centimetro (cm)", "decimetro (dm)", "metro (m)", "decametro (dam)", "hectometro (hm)", "quilometro (km)"]} valor_conversao={10}/>
        <Medidas isMobile={isMobile} title="Medidas de capacidade" array={["mililitro (ml)", "centilitro (cl)", "decilitro (dl)", "litro (l)", "decalitro (dal)", "hectolitro (hl)", "quilolitro (kl)"]} valor_conversao={10}/>
        <Medidas isMobile={isMobile} title="Medidas de massa" array={["miligrama (mg)", "centigrama (cg)", "decigrama (dg)", "grama (g)", "decagrama (dag)", "hectograma (hg)", "quilograma (kg)"]} valor_conversao={10}/>
        <Medidas isMobile={isMobile} title="Medidas de volume" array={["milimetro cubico (mm³)", "centimetro cubico (cm³)", "decimetro cubico (dm³)", "metro cubico (m³)", "decametro cubico (dam³)", "hectometro cubico (hm³)", "quilometro cubico (km³)"]} valor_conversao={1000}/>
        <Medidas isMobile={isMobile} title="Medidas de superfice" array={["milimetro quadrado (mm²)", "centimetro quadrado (cm²)", "decimetro quadrado (dm²)", "metro quadrado (m²)", "decametro quadrado (dam²)", "hectometro quadrado (hm²)", "quilometro quadrado (km²)"]} valor_conversao={100}/>
        <Medidas isMobile={isMobile} title="Medidas agrárias" array={["centiare (ca) = m²", "are (a)", "hectare (ha)"]} valor_conversao={100}/>
        <Tempo isMobile={isMobile} title="Medidas de tempo" array={["milésimo de segundo", "centésimo de segundo", "décimo de segundo", "segundo (seg)", "minuto (min)", "hora (h)", "dia", "semana"]} />
        <div id="medidas_informacoes">
          <h2>A origem das medidas</h2>
          <p>
            <span>Atualmente</span> temos a nossa disposição diversos instrumentos que nos 
            permitem fazer medições de peso, comprimento e até do tempo. Mas você
            já parou para pensar como as coisas eram medidas antigamente?
          </p>
          <p>
            <span>A</span> resposta está no corpo humano. É isso mesmo! Há mais ou menos 4.000 anos,
            o povo do egíto usava o cúbito como padrão de medida. <br/>
            <span>O</span> cúbito era medido do cotovelo até ponta do dedo do meio.
          </p>
          <h2>Outras medidas de comprimentos</h2>
          <p>
          <span>Para</span> comerciantes, artesões e trabalhadores em geral, sempre foi importantissimo
            há existencia de medidas. Assim poderiam ter noção dos comprimentos qure precisariam
            criar suas peças, tecidos etc.
          </p>
          <p>
          <span>Por</span> exemplo, se a medida padrão de uma vara de tecido fosse 5 pés, não
            importava se o artesão fosse de Jerusalém ou alguma cidade visinha. Quem 
            procurasse esse tipo de produto, saberia em média o tamanho que teria o seu tecido.
          </p>
          <p>
            <span>Além</span> dos pés outras partes do corpo que eram e são usadas como medidas 
            até hoje são: A polegada, o palmo e a jarda.
          </p>
          <p>
            <img src={require('./../img/medidas-corpo-humano.jpg')} />
          </p>
          <h2>O sistema de medidas atual</h2>
          <p>
            <span>O</span> quilo, metro e litro como conhecemos hoje, começaram a ser estabelecidos
            na França à partir de 1790, quando a Assembleia Nacional Constituinte Francesa
            se pronunciou a favor de um sistema único e simples de medidas que
            pudessem ser usadas em todo o mundo.
          </p>
          <p>
            <span>O</span> sistema criado na França foi adotado pelo Brasil só em 26 de junho de 1862.
          </p>
          <p>
            <span>O</span> sistema internacional de medidas é adotado praticamente em todo o mundo. 
            As principais exceções são os Estados Unidos, Myanmar e a Libéria.
          </p>
        </div>
      </div>
    </>
  );
}
import {NavLink} from 'react-router-dom';

export default function Header(){
  return(
    <div id="header">
      <span>Ajude a manter o&nbsp;<span id="header-nome-site">Medidas Fácil</span><br id="header-br"/>&nbsp;Pix: surdinho.na.direita@gmail.com</span>
      <div id="header-menu">
        <NavLink className="header-link" id="link-medidas-nacionais" to="/home"> Medidas<br/>nacionais</NavLink>
        <NavLink className="header-link" id="link-medidas-internacionais" to="/internacionais"> Medidas<br/>internacionais</NavLink>
        <NavLink className="header-link" id="link-medidas-conversor-temperatura" to="/conversor_de_temperaturas" >Conversor de<br/>temperaturas</NavLink>
      </div>
    </div>
  )
}